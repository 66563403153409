import Form from "./_form.js";

const $SOCIAL_MEDIA_PREFIXES = ["bluesky", "mastodon", "linkedin"];

export default class AttendeeForm extends Form {

	serialize() {
		const socials = [];
		for(const prefix of $SOCIAL_MEDIA_PREFIXES) {
			const handle = String.fromInput(this.ƒ[prefix]);
			handle != null && socials.push([prefix, handle].join(":"));
		}
		return {
			ticketId:    String.fromInput(this.ƒ.ticketId),
			name:        String.fromInput(this.ƒ.name),
			email:       String.fromInput(this.ƒ.email),
			affiliation: String.fromInput(this.ƒ.affiliation),
			countryCode: String.fromInput(this.ƒ.countryCode),
			pronouns:    String.fromInput(this.ƒ.pronouns),
			diet:        String.fromInput(this.ƒ.diet),
			hidden:			 !this.ƒ.hidden.checked,
			socials
		}
	}

	async submit() {
		this.$$.submit?.dataset.add("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
			if(this.data?.id != null) {
				this.$.method = "PATCH";
				this.$.action = `/api/attendees/${this.data.id}`;
				this.ƒ.ticketId.remove();
			}
		}
		finally {
			this.$$.submit?.dataset.remove("pending");
			this.$$.message?.show();
		}
	}

}
