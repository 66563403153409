const numberFormats = {
	integer:   {useGrouping: true, maximumFractionDigits: 0},
	float:     {useGrouping: true, maximumFractionDigits: 2},
	amount:    {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}
};

const dateFormats = {
	short:   {day: "2-digit", month: "2-digit", year: "numeric"},
	medium:  {day: "numeric", month: "long",    year: "numeric"},
	long:    {day: "numeric", month: "long",    year: "numeric"},
	tabular: {day: "2-digit", month: "short",   year: "numeric"}
};

const timeFormats = {
	moment:   {hour: "numeric", minute: "2-digit"},
	tabular:  {hour: "2-digit", minute: "2-digit"},
	duration: {hour: "2-digit", minute: "2-digit", second: "2-digit"}
};

// @todo: Import and use formatting functions from lib/format.js

const filters = {

	// encodeURI,
	// encodeURIComponent,

	// min:   Math.min,
	// max:   Math.max,
	// round: Math.round,
	// floor: Math.floor,
	// ceil:  Math.ceil,
	// trunc: Math.trunc,

	// toString: value => value.toString(),
	// toJSON:   JSON.stringify,
	// fromJSON: JSON.parse,

	isTruthy: value => !!value,
	isFalsy:  value => !value,
	ternary:  (value, yep, nope) => !!value ? yep : nope,

	is:        (a, b) => a == b,
	isNot:     (a, b) => a != b,
	isMore:    (a, b) => a > b,
	isLess:    (a, b) => a < b,
	isAtMost:  (a, b) => a <= b,
	isAtLeast: (a, b) => a >= b,
	// isInRange: (value, min, max) => value >= min && value <= max,

	isNull:    value => value == null,
	isNotNull: value => value != null,

	and:      (a, b) => a && b,
	or:       (a, b) => a || b,
	// coalesce: (a, b) => a ?? b,

	add:      (...values) => values.reduce((a, b) => a + b),
	subtract: (...values) => values.reduce((a, b) => a - b),
	multiply: (...values) => values.reduce((a, b) => a * b),
	divide:   (...values) => values.reduce((a, b) => a / b),

	length:    value => value.length,
	// includes:  (value, target)    => value.includes(target),
	// item:      (value, index)     => value[index],
	// split:     (value, delimiter) => value.split(delimiter),
	// slice:     (value, ...args)   => value.slice(...args),
	// join:      (value, delimiter) => value.join(delimiter),

	toUpperCase:  value => value.toUpperCase(),
	toLowerCase:  value => value.toLowerCase(),
	// toKebabCase:  value => value.toKebabCase(),
	// toCamelCase:  value => value.toCamelCase(),
	// toPascalCase: value => value.toPascalCase(),

	// replace: (value, a, b) => value.replace(a, b),
	singularize: value => value.replace(/{{.+?}}/g, ""),
	pluralize:   value => value.replace(/{{|}}/g, ""),

	formatNumber: (value, format="integer") => new Number(value).toLocaleString($LOCALE_INTL, numberFormats[format]),
	formatDate:   (value, format="short")   => new Date(value).toLocaleDateString($LOCALE_INTL, dateFormats[format]),
	formatTime:   (value, format="moment")  => new Date(value).toLocaleTimeString($LOCALE_INTL, timeFormats[format]),

	displayName: (value, type) => new Intl.DisplayNames($LOCALE_INTL, {type}).of(value)

};

const getElements = (function (root, query) {
	return Array.from(this.evaluate(query, root, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE));
})
.bind(new XPathEvaluator());

const parseValue = (value, context) => {
	const [expression, ...filters_] = value.split(/\s*\|\s*/);
	const value_ = parseExpression(expression, context);
	return filters_.reduce(
		(result, filter) => {
			const [name, ...args] = filter.match(/(?:[^\s"']+|["'][^"']*["'])+/g);
			if(!(name in filters)) {
				throw `Template filter [${name}] does not exist.`
			}
			return filters[name](result, ...args.map(arg => parseExpression(arg, context)));
		},
		value_
	);
};

const parseExpression = (expression, context) => {

	// String, number or boolean expression:
	if(/^['"].*['"]$/.test(expression)) { return expression.replaceAll(/^['"]|['"]$/g, "").replace(/\\n/g, "\n"); }
	if(/^\-?(\d+(\.\d*)?|\.\d+)$/.test(expression)) { return Number(expression); }
	if(["true", "false"].includes(expression)) { return expression == "true"; }

	// Context path expression:
	return expression.replace(/\[(\d+)\]/g, ".$1").split(".").reduce((obj, key) => obj && obj[key], context);

};

export default class Template {

	constructor(template, remove=true) {
		this.template = template;
		this.root = document.implementation.createHTMLDocument().body;
		remove && template.remove();
		return (target, context, action)  => this.render(target, context, action);
	}

	getElements(prefixes) {
		const selectors = prefixes.map(prefix => `starts-with(name(), "${prefix}")`).join(" or ");
		return getElements(this.root, `.//*[@*[${selectors}]]`).reverse().map(element => [
			element,
			Array.from(element.attributes).filter(attr => prefixes.some(prefix => attr.name.startsWith(prefix)))
		]);
	}

	getContext(element) {
		const contexts = [];
		while(element != null) {
			this.contexts.has(element) && contexts.unshift(this.contexts.get(element));
			element = element.parentNode;
		};
		return Object.assign({}, ...contexts);
	}

	setContext(element, context) {
		this.contexts.set(element, {...this.contexts.get(element), ...context});
	}

	render(target, context, action="replaceChildren") {
		return new Promise((resolve, reject) => {

			this.root.replaceChildren(this.template.content.cloneNode(true));

			const context_ = structuredClone(context);
			context_.$root = context_;
			context_.window = window;
			context_.localStorage = localStorage;

			this.contexts = new WeakMap();
			this.setContext(this.root, context_);

			// $each:
			// These need to go recursive, as each loop creates new nodes which may contains more loops,
			// which may contain more loops, which may contain... It's turtles all the way down.
			const evaluator = new XPathEvaluator();
			const query = `.//*[@*[starts-with(name(), "$each:")]]`;
			let element;
			do {
				element = evaluator.evaluate(query, this.root, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
				if(element != null) {
					const attr = Array.from(element.attributes).find(attr => attr.name.startsWith("$each:"));
					const [_, key] = attr.name.split(":");
					const value = parseValue(attr.value, this.getContext(element));
					const entries = Array.isArray(value) ? Array.from(value.entries()) : Object.entries(value);
					element.removeAttributeNode(attr);
					entries.forEach(([key_, value], i) => {
						const element_ = element.cloneNode(true);
						this.setContext(element_, {[key.toCamelCase()]: value, $key: key_, $index: i, $length: entries.length});
						element.before(element_);
					});
					element.remove();
				}
			}
			while(element != null);

			// $set:
			for(const [element, attrs] of this.getElements(["$set:"])) {
				for(const attr of attrs) {
					const [_, key] = attr.name.split(":");
					this.setContext(element, {[key.toCamelCase()]: parseValue(attr.value, this.getContext(element))});
					element.removeAttributeNode(attr);
				}
			};

			// $if and $unless:
			for(const [element, [attr]] of this.getElements(["$if", "$unless"])) {
				const unless = attr.name == "$unless";
				!(!!parseValue(attr.value, this.getContext(element)) ^ unless) && element.remove();
				element.removeAttributeNode(attr);
			};

			// Expression attributes:
			for(let attr of getElements(this.root, './/*/@*[contains(., "${")]')) {
				attr.value = attr.value.replace(
					/\${\s*(.+?)\s*}/g,
					(match, value) => parseValue(value, this.getContext(attr.ownerElement))
				);
			}

			// $attr:
			// for(const [element, attrs] of this.getElements(["$attr:"])) {
			// 	for(const attr of attrs) {
			// 		const [_, key] = attr.name.split(":");
			// 		element.setAttribute(key, parseValue(attr.value, this.getContext(element)));
			// 		element.removeAttributeNode(attr);
			// 	}
			// };

			// $attr-if and $attr-unless:
			for(const [element, attrs] of this.getElements(["$attr-if:", "$attr-unless:"])) {
				for(const attr of attrs) {
					const unless = attr.name.startsWith("$attr-unless:");
					const [_, key] = attr.name.split(":");
					(!!parseValue(attr.value, this.getContext(element)) ^ unless) && element.setAttribute(key, "");
					element.removeAttributeNode(attr);
				}
			};

			// Expression text nodes:
			for(let node of getElements(this.root, '//*/text()[contains(., "${")]')) {
				node.textContent = node.textContent.replace(
					/\${\s*(.+?)\s*}/g,
					(match, value) => parseValue(value, this.getContext(node.parentElement))
				);
			}

			// Move attributes on <attr> elements to their parent:
			for(let element of Array.from(this.root.getElementsByTagName("attr"))) {
				for(const attr of element.attributes) {
					element.parentElement.setAttributeNode(attr.cloneNode());
				}
				element.remove();
			}

			// $collapse:
			for(const [element] of this.getElements(["$collapse"])) {
				element.replaceChildren(
					...Array.from(element.childNodes)
					.filter(node => node.nodeType != Node.TEXT_NODE || /\S/.test(node.textContent))
				);
			}

			// Replace <content> elements with their content:
			for(let element of Array.from(this.root.getElementsByTagName("content"))) {
				element.replaceWith(...element.childNodes);
			}

			// Replace <text> elements with their text content:
			for(let element of Array.from(this.root.getElementsByTagName("text"))) {
				element.replaceWith(element.textContent);
			}

			this.root.normalize();
			switch(action) {
				case "replaceChildren": target.replaceChildren(...this.root.childNodes); break;
				case "replaceWith":     target.replaceWith(...this.root.childNodes);     break;
				case "prepend":         target.prepend(...this.root.childNodes);         break;
				case "append":          target.append(...this.root.childNodes);          break;
			}
			resolve();

		});
	}

};
