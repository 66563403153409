import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class IndexPage extends Component {

	constructor($, $$) {

		super(...arguments);

		const categoryDetails = $$.categoryDetails.component;
		const categoryForm =    $$.categoryForm.component;
		const indexForm =       $$.indexForm.component;

		const handler = async event => {
			if(indexForm.highlightCategory(categoryForm.ƒ.token.value)) {
				event.preventDefault();
				categoryDetails.toggle();
				categoryForm.reset();
			}
		};
		listen(categoryForm.$, "submit", handler, {capture: true, passive: false});

		listen(categoryForm.$, "load", async event => {
			await indexForm.appendCategory(event.detail.category);
			categoryDetails.toggle();
		});

		const params = new URLSearchParams(location.search);
		params.has("token") && sleep(200, async () => {
			await categoryDetails.toggle();
			categoryForm.applyToken(params.get("token"))
		});

	}

}
