import {listen} from "../lib/component.js";
import Component from "./_component.js";

const $TOGGLE_ANIMATION_DURATION = 200;

export default class Details extends Component {

	constructor($, $$) {
		super(...arguments);
		listen($, "toggle", () => $.open && this.focus());
	}

	async toggle() {
		this.$.open = !this.$.open;
		await sleep($TOGGLE_ANIMATION_DURATION);
	}

	async focus() {
		await sleep($TOGGLE_ANIMATION_DURATION)
		this.$.querySelector("[autofocus]")?.focus();
	}

}
