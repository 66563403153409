import Component from "./_component.js";

// Removed "fk" and "gs" from this list: names are too long.
const $ISO_3166_ALPHA_2_CODES = [
	"af","ax","al","dz","as","ad","ao","ai","aq","ag","ar","am","aw","au","at","az","bs","bh","bd","bb","by","be","bz",
	"bj","bm","bt","bo","bq","ba","bw","bv","br","io","bn","bg","bf","bi","cv","kh","cm","ca","ky","cf","td","cl","cn",
	"cx","cc","co","km","cg","cd","ck","cr","ci","hr","cu","cw","cy","cz","dk","dj","dm","do","ec","eg","sv","gq","er",
	"ee","sz","et","fo","fj","fi","fr","gf","pf","tf","ga","gm","ge","de","gh","gi","gr","gl","gd","gp","gu","gt","gg",
	"gn","gw","gy","ht","hm","va","hn","hk","hu","is","in","id","ir","iq","ie","im","il","it","jm","jp","je","jo","kz",
	"ke","ki","kp","kr","kw","kg","la","lv","lb","ls","lr","ly","li","lt","lu","mo","mg","mw","my","mv","ml","mt","mh",
	"mq","mr","mu","yt","mx","fm","md","mc","mn","me","ms","ma","mz","mm","na","nr","np","nl","nc","nz","ni","ne","ng",
	"nu","nf","mk","mp","no","om","pk","pw","ps","pa","pg","py","pe","ph","pn","pl","pt","pr","qa","re","ro","ru","rw",
	"bl","sh","kn","lc","mf","pm","vc","ws","sm","st","sa","sn","rs","sc","sl","sg","sx","sk","si","sb","so","za","ss",
	"es","lk","sd","sr","sj","se","ch","sy","tw","tj","tz","th","tl","tg","tk","to","tt","tn","tr","tm","tc","tv","ug",
	"ua","ae","gb","us","um","uy","uz","vu","ve","vn","vg","vi","wf","eh","ye","zm","zw"
];

export default class CountrySelect extends Component {

	constructor($, $$) {

		super(...arguments);

		const name = new Intl.DisplayNames($LOCALE_INTL, {type: "region"});
		this.data = $ISO_3166_ALPHA_2_CODES.map(code => [name.of(code.toUpperCase()), code]);
		this.data.sort();
		this.render();

	}

	async render() {
		this.$.append(document.createElement("hr"));
		this.data.forEach(([name, code]) => {
			const selected = code == this.$.dataset.value;
			this.$.options.add(new Option(name, code, selected, selected));
		});

	}

}
