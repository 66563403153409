import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class CategoryForm extends Form {

	constructor($, $$) {
		super(document.getElementById($.dataset.root), $$, {body: $}); // Root element override.
		listen(this.ƒ.token, "input", () => this.ƒ.token.value = this.ƒ.token.value.replace(/\s+/g, "-"));
	}

	applyToken(token) {
		this.ƒ.token.value = token;
		this.$.requestSubmit();
	}

	serialize() {
		return {token: String.fromInput(this.ƒ.token)};
	}

	invalidate() {
		const input = this.ƒ.token;
		input.setCustomValidity("$TICKET_CATEGORY_DOES_NOT_EXIST");
		this.validate();
		this.$$.submit.dataset.add("warning");
		listen(input, "input", () => this.unvalidate(), {once: true});
	}

	unvalidate() {
		this.ƒ.token.setCustomValidity("");
		this.$$.submit.dataset.clear();
		this.$$.messages.invalid.hide();
		this.$$.messages.soldOut.hide();
	}

	reset() {
		this.unvalidate();
		this.$.reset();
	}

	async submit() {
		this.$$.submit.dataset.add("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
		}
		finally {
			this.$$.submit.dataset.remove("pending");
		}
		if(this.data == null) {
			// The requested category does not exist.
			this.invalidate();
			this.$$.messages.invalid.show();
		}
		else if(!this.data.ticketCountAvailable) {
			// The requested category exists but has sold out.
			this.invalidate();
			this.$$.messages.soldOut.show();
		}
		else {
			this.dispatch("load", {category: this.data});
			this.$$.submit.dataset.add("success");
			this.reset();
		}

	}

}
