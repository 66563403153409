import {listen} from "../lib/component.js";
import Template from "../lib/template.js";
import Form from "./_form.js";

const $APPEND_CATEGORY_ANIMATION_DURATION = 400;
const $HIGHLIGHT_CATEGORY_ANIMATION_DURATION = 1_000;

export default class IndexForm extends Form {

	constructor($, $$) {

		super(...arguments, {template: new Template($$.template)});

		listen($$.canvas, "input", event => {
			const input = event.target;
			if(!(input.validity.valid && !!input.value.length)) {
				input.value = "";
			}
			const inputs = this.ƒƒ.quantity;
			const required = !inputs.some(input_ => input_.validity.valid && !!input_.value.length);
			inputs.forEach(input_ => input_.required = required);
		});

		// @debug:
		listen(document, "keydown:F1", () => {
			this.ƒƒ.quantity[0].value = 2;
			this.ƒƒ.quantity[0].dispatchEvent(new Event("input", {bubbles: true}));
			this.ƒ.name.value =  "Testy McTestface";
			this.ƒ.email.value = "test@test.test";
			this.ƒ.agree.checked = true;
		});

	}

	highlightCategory(token) {
		const token_ = token.toLowerCase();
		const fields = Array.from(this.$$.canvas.querySelectorAll("[data-token"));
		const field = fields.find(field => field.dataset.token.toLowerCase() == token_);
		if(field != null) {
			field.dataset.add("highlight");
			sleep($HIGHLIGHT_CATEGORY_ANIMATION_DURATION, () => {
				field.dataset.remove("highlight");
				field.querySelector("input:required")?.focus();
			});
			return true;
		};
		return false;
	}

	appendCategory(category) {
		this.template(this.$$.canvas, {...category, index: Date.now()}, "append");
		sleep($APPEND_CATEGORY_ANIMATION_DURATION, () => {
			const field = Array.from(this.$$.canvas.querySelectorAll("[data-token")).pop();;
			field.dataset.remove("animate");
			field.querySelector("input[required]")?.focus();
		});
	}

	serialize() {
		const ids = this.ƒƒ.id.map(input => String.fromInput(input));
		const quantities = this.ƒƒ.quantity.map(input => Number.fromInput(input));
		const ticketQuantities = Object.fromEntries(ids.map((id, i) => [id, quantities[i]]));
		return {
			ticketQuantities,
			name:               String.fromInput(this.ƒ.name),
			email:              String.fromInput(this.ƒ.email),
			invoiceName:        String.fromInput(this.ƒ.invoiceName),
			invoiceAddress:     String.fromInput(this.ƒ.invoiceAddress),
			invoiceCountryCode: String.fromInput(this.ƒ.invoiceCountryCode)
		}
	}

	async submit() {
		this.$$.submit.dataset.add("pending");
		try {
			await Promise.all([super.submit(), sleep(500)]);
			location.assign(new URL(`/orders/${this.data.id}/payment`, location.origin));
		}
		finally {
			this.$$.submit.dataset.remove("pending");
		}
	}

}
