import {listen, fetchJSON} from "../lib/component.js";
import Template from "../lib/template.js";
import Component from "./_component.js";

export default class AttendeesPage extends Component {

	constructor($, $$) {
		const templates = Object.fromEntries(
			Object.entries($$.templates).map(([key, template]) => [key, new Template(template)])
		);
		super(...arguments, {templates});
		listen($$.save, "click", () => this.save());
		this.load();
	}

	async load() {

		const id = location.pathname.split("/")[2];
		const url = new URL(`/api/orders/${id}`, location.origin);
		try {
			const [data] = await Promise.all([fetchJSON("GET", url, {throwOn404: true}), sleep(500)]);
			this.data = data;
		}
		catch(error) {
			this.dispatch("error", {error});
			throw(error);
		}

		const paymentComplete = this.data.paymentComplete;
		const attendeesComplete = this.data.categories.every(
			category => category.tickets.every(ticket => ticket.attendeeId != null)
		);
		this.$$.paymentLink.href = `/orders/${id}/payment`;
		this.$$.steps[0].dataset.add("complete");
		paymentComplete && sleep(200, () => this.$$.steps[1].dataset.add("complete"));
		attendeesComplete && sleep(this.data.paymentComplete ? 400 : 200, () => this.$$.steps[2].dataset.add("complete"));

		const attendees = this.data.categories.map(category => category.tickets.map(ticket => ({
			categoryId: category.id,
			categoryName: category.name,
			...(
				ticket.attendeeId != null
				? this.data.attendees.find(attendee => attendee.ticketId == ticket.id)
				: {ticketId: ticket.id, socials: {}, hidden: false}
			)
		}))).flat();
		this.templates.attendees(this.$$.canvases.attendees, attendees);
		this.templates.reminder(this.$$.canvases.reminder, this.data, "replaceWith");

	}

	async save() {
		this.$$.save.dataset.add("pending");
		try {
			const forms = Array.from(this.$$.canvases.attendees.querySelectorAll("form"));
			await Promise.all([...forms.map(({component}) => component.$.requestSubmit()), sleep(500)]);
		}
		finally {
			this.$$.save.dataset.remove("pending");
			this.$$.message.show();
		}
	}

}
