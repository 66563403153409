import {listen} from "../lib/component.js";
import Form from "./_form.js";

export default class PaymentForm extends Form {

	constructor($, $$) {
		super(...arguments);
		listen($, "change", () => this.setMode());
	}

	get action() {
		const id = location.pathname.split("/")[2];
		return new URL(`/api/orders/${id}/payments`, location.origin);
	}

	setMode() {
		switch(this.$.elements.mode.value) {
			case "online":
				this.$$.messages.online.show();
				this.$$.messages.invoice.hide();
				break;
			case "invoice":
				this.$$.messages.online.hide();
				this.$$.messages.invoice.show();
				break;
		}
	}

	serialize() {
		// @todo: Offer payment method and issuer pre-selection?
		return {
			methodId: undefined,
			issuerId: undefined
		}
	}

	async submit() {

		if(this.$.elements.mode.value == "invoice") {
			const id = location.pathname.split("/")[2];
			location.assign(new URL(`/orders/${id}/attendees`, location.origin));
			return;
		}

		this.$$.submit.dataset.add("pending");
		try {
			await super.submit();
			location.assign(this.data.checkoutUrl);
		}
		finally {
			this.$$.submit.dataset.remove("pending");
		}

	}

}
