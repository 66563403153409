import {listen} from "../lib/component.js";
import Component from "./_component.js";

export default class ErrorMessage extends Component {

	constructor($, $$) {

		super(...arguments);

		listen($, "error", event => this.show(event.detail.error));
		listen($$.hide, "click", () => this.hide());
		listen($$.dump, "click", () => $$.dump.select());

	}

	show(error) {
		this.$$.dump.value = `${new Date().toISOString()}\n${error.constructor.name}\n${error.message}`;
		this.$$.dialog.showModal();
	}

	hide() {
		this.$$.dialog.close();
	}

}
